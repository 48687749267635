import { useState } from "react";
import type { ChangeEvent } from "react";
import { Range, Root, Thumb, Track } from "@radix-ui/react-slider";
import { t } from "@/i18n-js/instance";
import { classNames } from "@/react/helpers/twMergeWithCN";
import { Icon } from "../Icon";

interface SliderWithInputProps<T extends string = string> {
  value?: number;
  onChange?: (value: number, unit?: T) => void;
  min?: number;
  max?: number;
  step?: number;
  className?: string;
  defaultUnit?: T;
  units?: ReadonlyArray<T>;
}

export function SliderWithInput<T extends string = string>({
  value: controlledValue,
  onChange,
  min = 0,
  max = 100,
  step = 1,
  className = "",
  defaultUnit,
  units = [],
}: Readonly<SliderWithInputProps<T>>) {
  const [internalValue, setInternalValue] = useState<number>(
    controlledValue ?? 50,
  );
  const [unit, setUnit] = useState<T | undefined>(defaultUnit ?? units[0]);

  const shouldShowUnitSelector = units.length > 0 || !!defaultUnit;

  const handleSliderChange = (newValue: number[]) => {
    const updatedValue = newValue[0];
    setInternalValue(updatedValue);
    onChange?.(updatedValue, unit);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue)) {
      const clampedValue = Math.max(min, Math.min(max, newValue));
      setInternalValue(clampedValue);
      onChange?.(clampedValue, unit);
    }
  };

  const handleUnitChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedUnit = units.find(u => u === e.target.value);
    if (selectedUnit) {
      setUnit(selectedUnit);
      onChange?.(internalValue, selectedUnit);
    }
  };

  return (
    <div
      className={classNames(
        "flex w-full max-w-md items-center space-x-4",
        className,
      )}
    >
      <Root
        className="relative flex h-6 w-full cursor-pointer items-center"
        value={[internalValue]}
        onValueChange={handleSliderChange}
        max={max}
        min={min}
        step={step}
      >
        <Track className="bg-disabled relative h-1 w-full rounded">
          <Range className="bg-dark-primary-button absolute h-full rounded" />
        </Track>
        <Thumb
          className="bg-dark-primary-button block h-4 w-4 rounded-full shadow-md"
          aria-label={t("slider.slider_thumb_aria_label")}
        />
      </Root>

      <div className="group flex items-center">
        <input
          type="number"
          value={internalValue}
          onChange={handleInputChange}
          className={classNames(
            "border-primary bg-primary text-dark w-fit border px-2 py-1 text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
            "focus:border-darkest group-focus-within:border-darkest focus:!shadow-none focus:!outline-none focus:!ring-0",
            "group-hover:border-darkest",
            shouldShowUnitSelector ? "rounded-l-lg border-r-0" : "rounded-lg",
          )}
          min={min}
          max={max}
          step={step}
        />

        {shouldShowUnitSelector && (
          <div className="relative -ml-px">
            <select
              value={unit}
              onChange={handleUnitChange}
              className="border-primary bg-primary text-dark group-hover:border-darkest focus:border-darkest group-focus-within:border-darkest w-fit appearance-none rounded-r-lg border px-2 py-1 pr-6 text-center focus:!outline-none focus:!ring-0"
            >
              {units.map(unitOption => (
                <option key={unitOption} value={unitOption}>
                  {unitOption}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2">
              <Icon type="12-chevron-down" size={12} className="text-default" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
