import type { ChangeEvent } from "react";
import { useState } from "react";
import { Popover } from "@headlessui/react";
import { HexColorPicker } from "react-colorful";
import { createPortal } from "react-dom";
import type { PopperProps } from "react-popper";
import { usePopper } from "react-popper";
import { t } from "@/i18n-js/instance";
import { Input } from "@circle-react-shared/uikit/Input";
import { HEADLESS_UI_POPOVER_ROOT } from "@circle-react-uikit/PopoverPortal";
import { Palette } from "./Palette";
import { useColorState } from "./useColorState";
import type { ColorChangePayload } from "./useColorState";

interface ColorPickerV2Props {
  onColorChange: (color: ColorChangePayload) => void;
  color?: string;
  shouldShowPalette?: boolean;
  popperOptions?: PopperProps<any>["modifiers"];
}

export const ColorPickerV2 = ({
  onColorChange,
  color: externalColor,
  shouldShowPalette = true,
  popperOptions = [],
}: ColorPickerV2Props) => {
  const { color, inputValue, handleColorChange, handleInputChange } =
    useColorState(externalColor);

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      ...popperOptions,
    ],
  });

  const portalRoot = document.getElementById(HEADLESS_UI_POPOVER_ROOT);

  return (
    <Popover as="div" className="relative" data-testid="color-picker-v2">
      <Popover.Button ref={setReferenceElement} className="focus:outline-none">
        <div
          className="border-primary h-7 w-7 rounded-lg border"
          style={{ backgroundColor: color }}
        />
      </Popover.Button>

      {createPortal(
        <Popover.Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="bg-primary border-primary z-[9999] flex w-[15rem] flex-col items-center justify-center gap-4 rounded-lg border p-4 shadow-lg"
        >
          <HexColorPicker
            color={color}
            onChange={color => handleColorChange(color, onColorChange)}
          />
          <Input
            value={inputValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, onColorChange)
            }
            className="form-control"
            placeholder={t("color_picker_v2.placeholder")}
          />
          <Palette
            shouldShowPalette={shouldShowPalette}
            color={color}
            handleColorChange={handleColorChange}
            onColorChange={onColorChange}
          />
        </Popover.Panel>,
        portalRoot ?? document.body,
      )}
    </Popover>
  );
};
