import { colord, extend } from "colord";
import harmoniesPlugin from "colord/plugins/harmonies";

extend([harmoniesPlugin]);

export const useColorPalette = (
  baseColor: string,
  shouldShowPalette = true,
) => {
  const generatePalette = (color: string): string[] => {
    if (!shouldShowPalette) return [];

    const base = colord(color);

    const analogousColors = base
      .harmonies("analogous")
      .map(color => color.toHex())
      .slice(1);

    const monochromaticColors = [
      base.lighten(0.1).toHex(),
      base.lighten(0.2).toHex(),
      base.darken(0.1).toHex(),
      base.darken(0.2).toHex(),
    ];

    const triadColors = base
      .harmonies("triadic")
      .map(color => color.toHex())
      .slice(1);

    const complementColor = base.harmonies("complementary")[1]?.toHex();

    const spinColors = [30, 60, 90].map(deg => base.rotate(deg).toHex());

    const palette = [
      ...analogousColors,
      ...monochromaticColors,
      ...triadColors,
      complementColor,
      ...spinColors,
    ].filter(Boolean);

    return Array.from(new Set(palette)).slice(0, 8);
  };

  return generatePalette(baseColor);
};
