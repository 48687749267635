import { useColorPalette } from "./useColorPalette";

interface PaletteProps {
  shouldShowPalette: boolean;
  handleColorChange: (
    color: string,
    onColorChange?: (payload: ColorChangePayload) => void,
  ) => void;
  onColorChange?: (payload: ColorChangePayload) => void;
  color: string;
}

interface ColorChangePayload {
  hex: string;
  rgb: [number, number, number];
}

export const Palette = ({
  shouldShowPalette,
  color,
  handleColorChange,
  onColorChange,
}: PaletteProps) => {
  const palette = useColorPalette(color, shouldShowPalette);

  if (!shouldShowPalette || palette.length === 0) return null;

  return (
    <>
      <div className="bg-tertiary h-px w-full rounded" />
      <div className="flex w-full flex-wrap justify-start gap-2">
        {palette.map(paletteColor => (
          <button
            key={paletteColor}
            type="button"
            className="border-primary h-7 w-7 rounded-lg border"
            style={{ backgroundColor: paletteColor }}
            title={paletteColor}
            onClick={() => handleColorChange(paletteColor, onColorChange)}
          />
        ))}
      </div>
    </>
  );
};
