import { useEffect, useState } from "react";
import type { ChangeEvent } from "react";
import { colord } from "colord";

export interface ColorChangePayload {
  hex: string;
  rgb: [number, number, number];
}

export const useColorState = (externalColor?: string) => {
  const [internalColor, setInternalColor] = useState("#b32aa9");
  const [inputValue, setInputValue] = useState(internalColor);

  useEffect(() => {
    if (externalColor && colord(externalColor).isValid()) {
      setInternalColor(externalColor);
      setInputValue(externalColor);
    } else {
      setInputValue(internalColor);
    }
  }, [externalColor, internalColor]);

  const handleColorChange = (
    newColor: string,
    onColorChange?: (payload: ColorChangePayload) => void,
  ) => {
    if (colord(newColor).isValid()) {
      setInternalColor(newColor);
      setInputValue(newColor);
      const { r, g, b } = colord(newColor).toRgb();
      onColorChange?.({ hex: newColor, rgb: [r, g, b] });
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    onColorChange?: (payload: ColorChangePayload) => void,
  ) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (colord(newValue).isValid()) {
      handleColorChange(newValue, onColorChange);
    }
  };

  return {
    color: externalColor ?? internalColor,
    inputValue,
    handleColorChange,
    handleInputChange,
  };
};
